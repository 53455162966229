import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLoggedIn } from 'bv-hooks';
import { registerHandler, unregisterHandler } from 'event-bus';
import { compose, isFunction } from 'underscore';
import { subscribeToBets } from 'SharedComponents/my_bets/subscribers';
import { RenderInNode } from 'bv-components';
import {
  fetchEventData, updateStatus, reset, setConfig,
} from '../ducks/reducer';

import StreamingButton from './streaming_button';
import Player from './player';

const App = ({
  status,
  eventId,
  sportId,
  isInPlay,
  renderPlayer,
  isEntitled,
  playerNode,
  reset: doReset,
  setConfig: doSetConfig,
  updateStatus: doUpdateStatus,
  fetchEventData: doFetchEventData,
}) => {
  const loggedIn = useLoggedIn();

  // Configure the current event
  // TODO: Only configuring to save data in the state to use later in the fetch
  // However, the fetch is providing this data as arguments too :clap
  // For now data is not provided in the fetch and used from state
  // Should be changed in a separate work
  useEffect(
    () => {
      doSetConfig(
        eventId,
        sportId,
        isInPlay,
        renderPlayer,
      );
      return doReset;
    },
    [sportId, eventId, isInPlay],
  );

  useEffect(() => {
    doFetchEventData();

    if (loggedIn) {
      // refetch event data when bets are placed to reassess user eligibility to watch the stream
      return subscribeToBets(doFetchEventData);
    }

    return undefined;
  }, [sportId, eventId, loggedIn]);

  // Handle changes in sport stream
  useEffect(
    () => {
      if (!isEntitled) return undefined;

      const address = `/sportstream/${eventId}`;
      const onEventUpdate = (err, { body: data }) => { doUpdateStatus(data.status); };

      registerHandler(address, onEventUpdate);
      return () => { unregisterHandler(address, onEventUpdate); };
    },
    [eventId, isEntitled],
  );

  if (status) {
    const node = isFunction(playerNode) ? playerNode() : playerNode;

    return (
      <>
        <StreamingButton />
        {renderPlayer && node && (
          <RenderInNode node={node}>
            <Player node={node} />
          </RenderInNode>
        )}
      </>
    );
  }

  return null;
};

App.propTypes = {
  eventId: PropTypes.number.isRequired,
  sportId: PropTypes.number.isRequired,
  isInPlay: PropTypes.bool.isRequired,
  playerNode: PropTypes.instanceOf(Element).isRequired,
  fetchEventData: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  isEntitled: PropTypes.bool.isRequired,
  renderPlayer: PropTypes.bool.isRequired,
  setConfig: PropTypes.func.isRequired,
};

const mapStateToProps = ({ streaming: { status, isEntitled, renderPlayer } }, ownProps) => ({
  status,
  isEntitled,
  renderPlayer: renderPlayer || ownProps.renderPlayer,
});

const mapDispatchToProps = (dispatch) => ({
  setConfig: compose(dispatch, setConfig),
  fetchEventData: compose(dispatch, fetchEventData),
  updateStatus: compose(dispatch, updateStatus),
  reset: compose(dispatch, reset),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
