export const buttonProps = (status) => ({
  finished: { text: 'javascript.media_streaming.button.unavailable', style: 'is-multiline disabled', disabled: true },
  available: { text: 'javascript.media_streaming.button.watch_race', style: '', disabled: false },
  bet_and_watch: { text: 'javascript.media_streaming.button.bet_to_watch', style: 'is-multiline', disabled: false },
  watch_and_bet: { text: 'javascript.media_streaming.button.watch_and_bet', style: 'is-multiline', disabled: false },
}[status] || { text: 'javascript.media_streaming.button.not_yet_available', style: 'is-multiline', disabled: false });

export const playerProps = (status) => ({
  not_logged_in: { text: 'javascript.inplay_streaming.error.not_logged_in', style: 'is-info' },
  not_eligible: { text: 'javascript.inplay_streaming.error.not_eligible', style: 'is-warning' },
  location_restricted: { text: 'javascript.inplay_streaming.error.location_restricted', style: 'is-danger' },
  generic_error: { text: 'javascript.inplay_streaming.error.generic', style: 'is-warning' },
  unauthorized: { text: 'javascript.inplay_streaming.error.generic', style: 'is-warning' },
  not_yet_available: { text: 'javascript.media_streaming.not_yet_available', style: 'is-warning' },
  bet_and_watch: { text: 'javascript.media_streaming.must_place_bet', style: 'is-info' },
  watch_and_bet: { text: 'javascript.media_streaming.watch_and_bet', style: 'is-info' },
}[status]);

export const inplayButtonProps = (status) => ({
  finished: { text: 'javascript.inplay_streaming.button.stream_ended', style: 'is-multiline disabled', disabled: true },
}[status] || { text: 'javascript.inplay_streaming.button.watch', style: '', disabled: false });
