import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { t } from 'bv-i18n';
import { Button, Icon } from 'bv-components';
import { togglePlayer } from '../ducks/reducer';
import { buttonProps, inplayButtonProps } from '../config';

const StreamingButton = ({
  isInPlay, status, renderPlayer, togglePlayer: doTogglePlayer,
}) => {
  const btn = isInPlay
    ? inplayButtonProps(status)
    : buttonProps(status);
  const isActive = renderPlayer && !btn.disabled ? 'active' : '';

  return (
    <Button
      type="button"
      className={`media-streaming-button bvs-button-card ${btn.style} ${isActive}`}
      disabled={btn.disabled}
      onClick={doTogglePlayer}
      noClass
    >
      <span dangerouslySetInnerHTML={{ __html: sanitize(t(btn.text)) }} />
      <Icon
        id="live-streaming"
        className="live-streaming-icon"
        inverted={isActive}
      />
    </Button>
  );
};

StreamingButton.propTypes = {
  togglePlayer: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  renderPlayer: PropTypes.bool.isRequired,
  isInPlay: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  status: state.streaming.status,
  renderPlayer: state.streaming.renderPlayer,
  providerId: state.streaming.providerId,
  isInPlay: state.streaming.isInPlay,
});

const mapDispatchToProps = (dispatch) => ({
  togglePlayer: () => dispatch(togglePlayer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StreamingButton);
