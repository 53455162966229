import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, compose } from 'underscore';
import { connect } from 'react-redux';
import { VideoPlayer, Spinner } from 'bv-components';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { playerProps } from '../config';
import { fetchStream } from '../ducks/reducer';
import { ProviderId } from '../helpers';

const Message = ({ style, text }) => (
  <div className="media-streaming-player media-streaming--message">
    <div className={`bvs-modal ${style}`}>
      <h3 className="bvs-modal__title">{t(text)}</h3>
    </div>
  </div>
);

Message.propTypes = {
  style: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const providerUrl = {
  1: (url, playerNode) => {
    const width = playerNode.clientWidth;
    return `${url}${url.includes('?') ? '&' : '?'}width=${width}`;
  },
};

const ifCustomPlayerConfig = (playerName) => ({
  custom_player_configuration: customPlayerConfiguration,
}) => (!isEmpty(customPlayerConfiguration) ? playerName : null);

const providerPlayerType = {
  [ProviderId.RACING_UK]: ifCustomPlayerConfig('RMGPlayer'),
  [ProviderId.UNIKRN]: (urlData) => (urlData.media_type === 'PLAYER' ? 'Iframe' : 'HlsPlayer'),
  // if provider is IMG and there's custom_player_configuration for the event
  // force ExternalImg player otherwise let the defaults go
  [ProviderId.IMG]: ifCustomPlayerConfig('ExternalImg'),
  [ProviderId.BET_GENIUS]: () => 'ShakaPlayer',
};

const providerClassNameMap = {
  1: 'media-streaming-player--racing-uk',
  2: 'media-streaming-player--at-the-races',
  9: 'media-streaming-player--unikrn',
  100: 'media-streaming-player--img',
};

const Player = ({
  status, urlData, fetchStream: doFetchStream, node,
}) => {
  const videoIsAvailable = status === 'available';

  useEffect(() => {
    if (videoIsAvailable && !urlData) doFetchStream();
  }, [status]);

  const messageProps = playerProps(status);

  if (messageProps) return <Message {...messageProps} />;

  if (videoIsAvailable) {
    if (!urlData) return <Spinner />;

    const providerId = urlData.provider_id;
    const className = classnames('media-streaming-player', providerClassNameMap[providerId]);
    const url = providerUrl[providerId]
      ? providerUrl[providerId](urlData.stream_url, node) : urlData.stream_url;
    const playerType = providerPlayerType[providerId]
      ? providerPlayerType[providerId](urlData)
      : null;

    return (
      <div className={className}>
        <VideoPlayer
          url={url}
          playerType={playerType}
          customPlayerConfiguration={urlData.custom_player_configuration}
          format={urlData.media_type}
          doFetchStream={doFetchStream}
          opts={{
            autoPlay: true,
            playsInline: true,
            controls: true,
            allow: 'fullscreen;autoplay',
          }}
        />
      </div>
    );
  }

  return null;
};

Player.propTypes = {
  status: PropTypes.string.isRequired,
  fetchStream: PropTypes.func.isRequired,
  urlData: PropTypes.arrayOf(Object).isRequired,
  node: PropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  status: state.streaming.status,
  urlData: state.streaming.urlData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStream: compose(dispatch, fetchStream),
});

export default connect(mapStateToProps, mapDispatchToProps)(Player);
