import { getJSON } from 'bv-fetch';

export const fetchEventApi = (eventId, sportId) => getJSON(`/bv_media_streaming/events/${eventId}?sport_id=${sportId}&current_account_only=true`);

export const fetchStreamApi = (eventId, sportId, providerId, format) => {
  const formatParam = format ? `&format=${format}` : '';
  return getJSON(`/bv_media_streaming/events/${eventId}/url?sport_id=${sportId}&provider_id=${providerId}${formatParam}`);
};

export const fetchUrlApi = (url) => fetch(url);
