import { Provider } from 'react-redux';
import App from './components/app';
import reducer from './ducks/reducer';

const { store, addReducers } = window.reduxState;

addReducers({
  streaming: reducer,
});

const MediaStreaming = (opts) => (
  <Provider store={store}>
    <App {...opts} />
  </Provider>
);

window.MediaStreaming = MediaStreaming;

export default MediaStreaming;
